<template>
  <div class="card-promotion" :class="{ 'not-modal': !modalStyle }">
    <div v-if="imageSrc" class="card-promotion__image">
      <atomic-picture :src="imageSrc" notLazy />
    </div>

    <div class="card-promotion__container">
      <div class="card-promotion__content">
        <div class="card-promotion__title">{{ cardInfo.title }}</div>
        <div class="card-promotion__value">{{ cardInfo.body }}</div>
      </div>

      <div v-if="!modalStyle" class="card-promotion__actions">
        <button-base class="card-promotion__actions-deposit" type="primary" size="md" @click="clickAction()">
          {{ buttonContent }}
        </button-base>

        <button-base class="card-promotion__actions-info" type="secondary-2" size="md" @click="openInfo()">
          <atomic-icon id="info" />
        </button-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CIPromotionsGroupCard, CIPromotionsPage } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    cardInfo: CIPromotionsGroupCard;
    modalStyle?: boolean;
    unavailable?: boolean;
  }>();
  const { openWalletModal, openModal } = useModalStore();
  const router = useRouter();

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const { getContent, localizePath } = useProjectMethods();

  const promotionsContent = inject<Ref<Maybe<CIPromotionsPage>>>('promotionsContent');
  const defaultLocalePromotionsContent = inject<Ref<Maybe<CIPromotionsPage>>>('defaultLocalePromotionsContent');

  const getLabel = (path: string): string =>
    getContent(promotionsContent?.value, defaultLocalePromotionsContent?.value, path);

  const imageSrc = props.cardInfo.image;
  const buttonContent = computed(() => {
    return isLoggedIn.value ? getLabel('cardButtonLabel') : getLabel('cardButtonSignUpLabel');
  });

  const clickAction = async (): Promise<void> => {
    await (isLoggedIn.value ? openWalletModal('deposit') : router.push(localizePath('/sign-up')));
  };

  const openInfo = async (): Promise<void> => {
    await openModal('promotions-card-detail', {
      props: {
        cardInfo: props.cardInfo,
        titleLabel: getLabel('cardModalTitle'),
        buttonLabel: buttonContent,
        buttonAction: clickAction,
      },
    });
  };
</script>

<style src="~/assets/styles/components/card/promotion.scss" lang="scss" />
